<template>
  <div
    ref="parentContainer"
    class="lg:px-auto px-gap lg:container"
    :class="modules.customValues ? modules.customValues.join(' ') : ''"
  >
    <div class="-mx-gap flex flex-wrap md:-mx-gap2">
      <div
        class="wrapper--copy order-first w-12/12 px-gap pb-2 text-center sm:ml-1/12 sm:w-10/12 md:pb-3 lg:ml-2/12 lg:w-8/12"
      >
        <template v-if="modules.content">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </template>
      </div>
      <div
        ref="tabsContainerOuter"
        class="lg:pl-auto wrapper__tabs relative h-fit bg-white px-gap sm:ml-1/12 lg:ml-auto"
        :class="[
          reversed
            ? 'wrapper__tabs--right lg:pr-gap2'
            : 'wrapper__tabs--left lg:pr-gap',
          tabsWrapperClasses,
        ]"
      >
        <div
          ref="tabsContainerInner"
          class="wrapper__tabs--inner absolute h-auto w-full bg-white pr-1"
        >
          <div
            v-for="(tab, index) in modules.tabs"
            :key="index"
            class="wrapper__tabs--inner-tab relative overflow-hidden border-b-1 border-gray-dark border-opacity-25"
          >
            <div
              class="wrapper__expand flex items-center pb-1 pt-1 hover:cursor-pointer"
              @click="handleTabClick(index)"
            >
              <h5
                class="cursor-pointer"
                :class="index == activeTab ? 'text-blue' : 'text-blue-dark'"
              >
                {{ tab.fields.label }}
              </h5>
              <svg
                v-if="index == activeTab"
                width="14"
                height="3"
                viewBox="0 0 14 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-auto"
              >
                <rect y="0.428711" width="14" height="2" fill="#0384FB" />
              </svg>
              <svg
                v-else
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ml-auto"
              >
                <rect y="6" width="14" height="2" fill="#0384FB" />
                <rect
                  x="6"
                  y="14"
                  width="14"
                  height="2"
                  transform="rotate(-90 6 14)"
                  fill="#0384FB"
                />
              </svg>
            </div>
            <transition name="fade">
              <div v-show="index == activeTab" class="wrapper__text pr-gap2">
                <template v-if="tab.fields.mobileMedia && device === 'm'">
                  <ImagesPicture
                    :image="tab.fields.mobileMedia.fields"
                    :classes="'w-full z-1 m-auto mb-[15px]'"
                  />
                </template>
                <template v-else-if="tab.fields.desktopMedia && device === 'd'">
                  <ImagesPicture
                    :image="tab.fields.desktopMedia.fields"
                    :classes="'w-full z-1 mb-[15px]'"
                  />
                </template>
                <template v-if="tab.fields.tabContent">
                  <RichTextRenderer
                    class="mt-3.5"
                    :document="tab.fields.tabContent"
                    :node-renderers="nodeRenderers"
                  />
                </template>
                <div
                  v-for="(cta, i) in tab.fields.link"
                  :key="'cta' + i"
                  class="mb-1"
                >
                  <ButtonsTheButton
                    v-if="cta.sys.contentType.sys.id === 'button'"
                    :button="cta.fields"
                  />
                  <LinksArrowLink
                    v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
                    :arrow-link="cta.fields"
                  />
                </div>
                <div
                  v-if="tab.fields.image.sys.contentType.sys.id === 'video'"
                  :ref="`video-${tab.fields.image.sys.id}`"
                  class="icon--video mt-space pb-1"
                >
                  <button
                    class="button border-0 bg-transparent outline-none hover:cursor-pointer"
                    aria-live="assertive"
                    :aria-label="`${state}`"
                    :aria-pressed="`${playing}`"
                    @click="toggleState()"
                    @keydown="toggleState()"
                  >
                    <svg
                      v-show="playing"
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="button-icon--solid"
                    >
                      <rect width="4" height="16" fill="#00263E" />
                      <rect x="8" width="4" height="16" fill="#00263E" />
                    </svg>
                    <svg
                      v-show="!playing"
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                    >
                      <path d="M14 8L0 0V16L14 8Z" fill="#00263E" />
                    </svg>
                  </button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div
        v-if="device !== 'm'"
        class="lg:pb-auto relative order-first px-gap pb-2 md:m-auto md:w-6/12 lg:ml-auto"
        :class="[
          reversed ? '' : 'lg:order-last',
          mediaColumnClasses,
          mediaFlexClasses,
        ]"
      >
        <div
          v-for="(tab, index) in modules.tabs"
          :key="'media' + index"
          class="w-full"
        >
          <div
            v-show="activeTab == index"
            class="wrapper--media relative z-10 h-0 w-full overflow-hidden"
            :class="[
              reversed ? 'lg:mr-gap' : 'lg:ml-gap',
              mediaClassObject(tab),
            ]"
          >
            <VideosTheVideo
              v-if="tab.fields.image.sys.contentType.sys.id === 'video'"
              :ref="tab.fields.image.sys.id"
              :state="state"
              :video="tab.fields.image.fields"
              class="w-full"
            />
            <ImagesPicture
              v-else
              :image="tab.fields.image.fields"
              classes="w-full z-1"
            />
          </div>
        </div>
        <template v-if="modules.media">
          <ImagesPicture
            :image="modules.media[0].fields"
            class="absolute inset-0 h-full w-full"
          />
        </template>
      </div>
      <TabsDevAccordionTabsPopup
        v-if="modules.slides"
        :reversed="reversed"
        :content="modules.slides[0].fields"
      />
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { AnalyticsEvents } from '~/types/enums/emitter-events';
  import { EventName } from '~/types/enums/mixpanel';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        state: 'play',
        observerConfig: {
          attributes: true,
          childList: true,
          subtree: true,
        },
        observer: null,
        device: null,
        playing: true,
        activeTab: 0,
        reversed: !!(this.modules.json && this.modules.json.reverse === true),
      };
    },
    computed: {
      mediaColumnClasses() {
        const num_columns =
          this.modules.featuredColumnWidthDesktop === undefined ||
          this.modules.featuredColumnWidthDesktop === '9 columns'
            ? 9
            : this.modules.featuredColumnWidthDesktop.charAt(0);

        return `w-12/12 sm:w-10/12 lg:w-${num_columns}/12`;
      },
      mediaFlexClasses() {
        return {
          'self-start':
            this.modules.featuredMediaHorizontalAlign === undefined ||
            this.modules.featuredMediaHorizontalAlign === 'Align Top',
          'self-center':
            this.modules.featuredMediaHorizontalAlign &&
            this.modules.featuredMediaHorizontalAlign === 'Align Center',
          'self-end':
            this.modules.featuredMediaHorizontalAlign &&
            this.modules.featuredMediaHorizontalAlign === 'Align Bottom',
        };
      },
      tabsWrapperClasses() {
        const num_columns =
          this.modules.tabsColumnWidthDesktop === undefined ||
          this.modules.tabsColumnWidthDesktop === '3 columns'
            ? 3
            : this.modules.tabsColumnWidthDesktop.charAt(0);

        return `w-12/12 sm:w-10/12 lg:w-${num_columns}/12`;
      },
    },
    watch: {
      playing() {
        if (!this.playing) {
          this.state = 'pause';
        } else {
          this.state = 'play';
        }
      },
    },
    mounted() {
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();
      this.createHeightMutationObserver();
      this.observer.observe(this.$refs.tabsContainerInner, this.observerConfig);
    },
    methods: {
      createHeightMutationObserver() {
        this.observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation) {
              if (
                this.$refs.tabsContainerOuter.offsetHeight <=
                this.$refs.tabsContainerInner.offsetHeight
              ) {
                this.$refs.tabsContainerOuter.style.height = `${this.$refs.tabsContainerInner.offsetHeight}px`;
              }
            }
          });
        });
      },
      toggleState() {
        this.playing = !this.playing;
      },
      onWindowResize() {
        this.device = window.innerWidth > 414 ? 'd' : 'm';
      },
      mediaClassObject(tab) {
        return {
          'lg:mr-gap': this.reversed,
          'lg:ml-gap': !this.reversed,
          'drop-shadow bg-white':
            tab.fields.dropshadow === undefined ||
            tab.fields.dropshadow === true,
        };
      },
      handleTabClick(index) {
        this.$emitter.emit(AnalyticsEvents.TRACK, {
          event: EventName.ACCORDION_TAB_CLICK,
          tab_index: index,
        });
        this.activeTab = index;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .module--accordion-tabs-hotspot {
    &.transparent {
      .wrapper__tabs--right {
        @apply bg-transparent;
        .wrapper__tabs--inner {
          @apply bg-transparent;
        }

        svg {
          rect {
            @apply fill-white;
          }
        }
      }

      .wrapper__tabs--inner-tab {
        border: 1px solid rgba(255, 255, 255, 0.5);
        @apply border-l-0 border-r-0 border-t-0;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s linear;
    overflow: hidden;
  }
  .fade-leave-to,
  .fade-enter,
  .fade-leave-active {
    transition: all 0.2s linear;
    max-height: 0px !important;
    opacity: 0;
  }
  button {
    &.button {
      &:focus {
        outline: none;
      }
      .icon {
        &--solid {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .wrapper--media {
    // max-height: 517px;
    padding-bottom: 100%; /* 8:5 ratio */

    :deep(.video) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.drop-shadow {
      filter: drop-shadow(0 6px 41px rgba(0, 0, 0, 0.3));
    }
  }
  .wrapper__tabs div:first-of-type .wrapper__expand {
    @screen md {
      @apply pt-2;
    }
  }
  .wrapper__expand {
    h5 {
      @apply mb-0 pb-0;
    }
  }
  .wrapper__tabs--left {
    border-top-left-radius: 25px;
    .wrapper__tabs--inner {
      border-top-left-radius: 25px;
    }
  }
  .wrapper__tabs--right {
    border-top-right-radius: 25px;
    .wrapper__tabs--inner {
      border-top-right-radius: 25px;
    }
  }
  .wrapper--copy {
    :deep(h5) {
      @apply text-blue;
    }
  }
  .wrapper__text {
    :deep(p) {
      margin-bottom: 15px;
    }
  }
  .icon--video {
    svg:hover path,
    svg:hover rect {
      fill: theme('colors.blue.DEFAULT');
    }
  }
</style>
